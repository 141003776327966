<template>
    <div class="propose-modal">
        <h1 v-html="$content('propose-title')"></h1>
        <div class="propose-modal_form">
        <AuthInput :readonly="true" placeholder="propose-subject"></AuthInput>
        <textarea v-model="text" required :placeholder="$content('propose-message')" rows="11"></textarea>
        <div v-if="errors.length">
            <p class="propose-modal_error" v-for="item in errors" :key="item">{{ $content(item) }}</p>
        </div>
        <div class="d-flex justify-content-end w-100">
            <button class="propose-modal_attach" @click="onAttachClick">
                <div class="d-flex justify-content-start">
                    <b-icon icon="paperclip"></b-icon>
                    <div v-html="$content('propose-attach')"></div>
                </div>
            </button>
        </div>
        <input @change="onFilesAdded" type="file" class="d-none" ref="propose" multiple />
        <div class="propose-modal_files" v-for="file in files">
            <b-icon icon="check"></b-icon>
            <span>{{ file.name }}</span>
        </div>
        <ProjectButton @click.native="onSubmit" variant="app">
            <div v-html="$content('propose-submit')"></div>
        </ProjectButton>
        </div>
    </div>
</template>

<script>
import AuthInput from "@/components/Inputs/AuthInput";
import ProjectButton from "@/components/Buttons/ProjectButton";
export default {
name: "ProposeNew",
    components: {ProjectButton, AuthInput},
    data() {
        return {
            files: null,
            text: '',
            errors: []
        }
    },
    methods: {
        onSubmit() {
            if (this.text) {
                const fd = new FormData();
                fd.append('text', this.text);

                if (this.files) {
                    this.files.forEach((file, index) => {
                        fd.append(`file${index}`, file);
                    })
                }

                this.$http('content').suggestNews(fd)
                    .finally(() => this.$modal.close())
            } else {
                this.errors.push('no-text-error');
            }
        },
        onAttachClick() {
            this.$refs.propose.click();
        },
        onFilesAdded() {
            this.files = this.$refs.propose.files;
        }
    },
    watch: {
        text() {
            this.errors = [];
        }
    }
}
</script>

<style lang="scss">
    .propose-modal {
        background: white;
        border-radius: 10px;
        padding: 40px;

        &_error {
            color: red;
            margin-bottom: 5px;
            line-height: 1;
            margin-top: 5px;
            font-size: 13px;
            font-family: Montserrat-Regular, sans-serif;
        }

        &_files {

            & svg {
                font-size: 18px;
                color: var(--main-titles_secondary);
            }

            & span {
                font-size: 13px;
                color: var(--main-dark-paper) !important;
            }
        }

        &_attach {
            font-family: Montserrat-Light, sans-serif !important;
            font-size: 12px;
            background: var(--main-input);
            border-radius: 200px;
            border: none;
            outline: none;
            padding: 5px 10px;
            width: 166px;
            margin: 20px 0;
            height: 33px;

            &:hover {
                background: #e4e4e4;
            }

            & svg {
                color: var(--main-dark-paper) !important;
                font-size: 15px;
                margin-right: 5px;
            }
        }

        & h1 {
            font-family: Montserrat-Medium, sans-serif !important;
            font-size: 18px !important;
            color: var(--main-dark-paper) !important;
            margin-left: 26px;
            margin-bottom: 33px;
        }

        &_form {

            & input {
                background: var(--main-input);
            }

            & textarea {
                width: 100%;
                background: var(--main-input);
                height: 150px;
                padding-left: 22px;
                padding-top: 10px;
                color: var(--main-dark-paper);
                font-family: Montserrat-Medium, sans-serif;
                font-size: 15px;
                box-shadow: 0px 0px 4px 1px #c0c0c070;
                outline: none !important;
                border: none;
                border-radius: 10px;
            }
        }
    }
</style>